<template>
  <div :class="prefixCls">
    <div>
      <!-- 工具栏 -->
      <Toolbar id="editor-toolbar" :editor="editor" :defaultConfig="toolbarConfig" />
      <!-- 编辑器 -->
      <div id="editor-container">
        <div id="title-container">
          <input placeholder="Page title..." v-model="titleContain">
        </div>
        <Editor id="editor-text-area" :editor="editor" v-model="html" :defaultConfig="editorConfig" @onChange="onChange"
          @onCreated="onCreated" />
        <div class="css-1fbmj9d"></div>
        <div class="css-1uz8l7v">发布设置</div>
        <!-- <div class="css-mfq34p"><label class="css-1nj8b26">添加封面</label>
          <div class="css-1pysja1">
            <div class="UploadPicture-wrapper css-1kx6s1g">
              <el-upload :action="this.define.comUploadUrl + '/userAvatar'" list-type="picture-card" :limit='1'
                :on-success="handleSuccess" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </div>
            <div class="css-uip80e">图片上传格式支持 JPEG、JPG、PNG</div>
          </div>
        </div> -->
        <div class="css-mfq34p"><label class="css-1nj8b26">添加描述</label>
          <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea">
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import define from "@/utils/define";
import request from "@/utils/request";
import { Boot, IButtonMenu, IDomEditor } from "@wangeditor/editor";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import markdownModule from "@wangeditor/plugin-md";
import { marked } from "marked";

Boot.registerModule(markdownModule);
// 添加自定义按钮 start
class MyButtonMenu {
  constructor() {
    this.title = "上传md(会覆盖现有编辑器中的内容！！)"; // 自定义菜单标题
    this.iconSvg =
      '<svg class="icon" style="width: 1.2em;height: 1.2em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="994"><path d="M522.666667 426.986667l165.930666 165.930666-133.824 133.802667v148.736h-64v-148.522667l-134.037333-134.016 165.930667-165.930666zM521.514667 183.68c125.717333 0 229.845333 92.522667 248 213.184l0.768 5.610667 3.776 0.085333c113.792 3.072 205.461333 94.741333 208.533333 208.533333l0.085333 5.888c0 114.048-89.109333 207.658667-202.453333 214.186667l-5.994667 0.256-6.058666 0.085333h-136v-64h135.509333l5.12-0.085333a150.506667 150.506667 0 1 0-20.053333-300.16l-7.616 0.96-37.184 5.696 0.362666-39.445333a186.794667 186.794667 0 0 0-186.794666-186.794667c-101.312 0-183.786667 80.682667-186.709334 180.736l-0.085333 5.461333 0.725333 39.189334-37.077333-5.141334a150.506667 150.506667 0 0 0-26.026667 299.498667l5.056 0.085333h135.978667v64h-135.978667c-118.464 0-214.506667-96.042667-214.506666-214.528 0-114.517333 89.749333-208.085333 202.730666-214.186666l7.125334-0.277334 0.789333-5.653333c17.834667-118.613333 118.762667-210.026667 241.621333-213.098667l6.357334-0.085333z m1.173333 333.781333l-75.434667 75.413334 75.434667 75.434666 75.413333-75.434666-75.413333-75.413334z" fill="#1677FF" p-id="995"></path></svg>'; // 可选
    this.tag = "button";
  }

  // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
  getValue(editor) {
    return " hello ";
  }

  // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
  isActive(editor) {
    return false;
  }

  // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
  isDisabled(editor) {
    return false;
  }

  // 点击菜单时触发的函数
  exec(editor, value) {
    if (this.isDisabled(editor)) return;
    var inputObj = document.createElement("input");
    inputObj.setAttribute("id", "my_inputObj");
    inputObj.setAttribute("type", "file");
    inputObj.setAttribute("style", "visibility:hidden");
    inputObj.setAttribute("accept", ".md");
    document.body.appendChild(inputObj);

    inputObj.onchange = () => {
      if (window.FileReader) {
        var file = inputObj.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          let cont = reader.reading({ encode: "UTF8" });
          let result = marked(cont);
          editor.setHtml(result);
        };
        reader.readAsBinaryString(file);
      }
    }; //选中文件时触发的方法
    inputObj.click();
    // editor.insertText(value); // value 即 this.value(editor) 的返回值
  }
}
const menu1Conf = {
  key: "menu1",
  factory() {
    return new MyButtonMenu();
  },
};
Boot.registerMenu(menu1Conf);
// 添加自定义按钮 end
export default {
  name: "WSYWang",
  components: { Editor, Toolbar },
  props: {
    prefixCls: {
      type: String,
      default: "wsy-editor-wang",
    },
    // 表单校验用字段
    // eslint-disable-next-line
    value: {
      type: String,
    },
    Id: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "请输入内容...",
    },
  },
  data() {
    return {
      editor: null,
      html: "",
      titleContain: "",
      dialogImageUrl: "",
      dialogVisible: false,
      textarea: "",
      imageUrl: [],
      toolbarConfig: {
        // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
        // excludeKeys: [ /* 隐藏哪些菜单 */ ],
        insertKeys: {
          index: 40,
          keys: [
            // "fillBlank",
            // "kityformula",
            // "ScriptMathWeb",
            // "editFormula", // “编辑公式”菜单
            "menu1",
          ],
        },
        excludeKeys: "fullScreen",
      },
      editorConfig: {
        // placeholder: '请输入内容...',
        placeholder: this.placeholder,
        // autoFocus: false,

        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          // 上传图片配置
          uploadImage: {
            server: this.define.comUploadUrl + "/forum",
            // form-data fieldName ，默认值 'wangeditor-uploaded-image'
            fieldName: "file",

            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 1 * 1024 * 1024, // 1M

            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 10,

            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ["image/*"],

            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            meta: {
              // token: this.$store.getters.token,
              // otherKey: 'yyy'
            },

            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,
            customInsert(res, insertFn) {
              // let url = res.data.url
              let href = define.APIURl + res.data.url;
              console.log(href);
              let alt = res.data.name;
              insertFn(href, alt, href);
            },
            // 自定义增加 http  header
            headers: {
              Authorization: this.$store.getters.token,
            },

            // 跨域是否传递 cookie ，默认为 false
            withCredentials: true,

            // 超时时间，默认为 10 秒
            timeout: 5 * 1000, // 5 秒
            onBeforeUpload(file) {
              console.log("onBeforeUpload", file);

              return file; // will upload this file
              // return false // prevent upload
            },
            onProgress(progress) {
              console.log("onProgress", progress);
            },
            onSuccess(file, res) {
              console.log("onSuccess", file, res);
            },
            onFailed(file, res) {
              alert(res.message);
              console.log("onFailed", file, res);
            },
            onError(file, err, res) {
              alert(err.message);
              console.error("onError", file, err, res);
            },
          },
          uploadVideo: {
            // /api/videos/
            server: this.define.APIURl + "/api/videos/forum",
            // form-data fieldName ，默认值 'wangeditor-uploaded-video'
            fieldName: "file",

            // 单个文件的最大体积限制，默认为 10M
            maxFileSize: 100 * 1024 * 1024, // 5M

            // 最多可上传几个文件，默认为 5
            maxNumberOfFiles: 3,

            // 选择文件时的类型限制，默认为 ['video/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ["video/*"],

            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            meta: {
              // token: this.$store.getters.token,
            },

            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,
            // 自定义插入视频
            customInsert(res, insertFn) {
              // res 即服务端的返回结果
              console.log(res, "result");
              let url = define.APIURl + "/api/videos/" + res.data.VideoId;
              // let url = 'http://47.104.160.44:9022' + '/api/videos/' + '368200581270996229'

              // 从 res 中找到 url poster ，然后插入视频
              insertFn(url);
            },
            // 自定义增加 http  header
            headers: {
              Authorization: this.$store.getters.token,
            },

            // 跨域是否传递 cookie ，默认为 false
            withCredentials: true,

            // 超时时间，默认为 30 秒
            timeout: 15 * 1000, // 15 秒

            // 视频不支持 base64 格式插入

            // 上传之前触发
            onBeforeUpload(file) {
              console.log("onBeforeUpload", file);

              // file 选中的文件，格式如 { key: file }
              return file;

              // 可以 return
              // 1. return file 或者 new 一个 file ，接下来将上传
              // 2. return false ，不上传这个 file
            },

            // 上传进度的回调函数
            onProgress(progress) {
              // progress 是 0-100 的数字
              console.log("progress", progress);
            },

            // 单个文件上传成功之后
            onSuccess(file, res) {
              console.log(`${file.name} 上传成功`, res);
            },

            // 单个文件上传失败
            onFailed(file, res) {
              console.log(`${file.name} 上传失败`, res);
            },

            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {
              console.log(`${file.name} 上传出错`, err, res);
            },
          },
        },

        hoverbarKeys: {
          // formula: {
          //     menuKeys: ['editFormula'], // “编辑公式”菜单
          // },
        },
      },
    };
  },
  mounted() {
    this.html = this.value;
  },
  beforeCreate() {
    /**
     * 读取文件（自定义函数）
     * @param pms {@link Object}：参数：
     *  @param pms.encode {@link String}：编码格式：
     * @return {@link String}：文本内容；
     */
    FileReader.prototype.reading = function ({ encode } = pms) {
      let bytes = new Uint8Array(this.result); //无符号整型数组
      let text = new TextDecoder(encode || "UTF-8").decode(bytes);
      return text;
    };
    /* 重写readAsBinaryString函数 */
    FileReader.prototype.readAsBinaryString = function (f) {
      if (!this.onload)
        //如果this未重写onload函数，则创建一个公共处理方式
        this.onload = (e) => {
          //在this.onload函数中，完成公共处理
          let rs = this.reading();
          // console.log(rs);
        };
      this.readAsArrayBuffer(f); //内部会回调this.onload方法
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 【注意】一定要用 Object.seal() 否则会报错
      this.editor.setHtml(this.html);
      this.editor.focus(true);
    },
    onChange(editor) {
      // console.log('onChange', editor.getHtml()) // onChange 时获取编辑器最新内容
      this.$emit("value", this.getEditorText());
    },
    getEditorText() {
      const editor = this.editor;
      if (editor == null) return;
      // console.log(editor.getText()) // 执行 editor API
      // console.log(ditor.getHtml(),'ditor.getHtml()');
      return editor.getHtml();
    },
    getEditorText2() {
      const editor = this.editor;
      if (editor == null) return;
      // console.log(editor.getText()) // 执行 editor API
      // console.log(ditor.getHtml(),'ditor.getHtml()');
      return editor.getText();
    },
    setContent(val) {
      this.editor.setHtml(val);
    },
    getTitleContain() {
      return this.titleContain;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleSuccess(res, file) {
      if (res.code == 200) {
        this.imageUrl.push({
          fileId: res.data.name,
          name: file.name,
          url: res.data.url
        });
      } else {
        this.$message({ message: res.msg, type: "error", duration: 1500 });
      }
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁 editor ，重要！！！
  },
};
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style>
#editor-toolbar {
  background-color: #fcfcfc;
  border-bottom: 1px solid #e8e8e8;
  border-top: 1px solid #e8e8e8;
  margin: 0 auto;
  position: sticky;
  top: 60px;
  z-index: 100;
}

#editor-content {
  background-color: rgb(245, 245, 245);
  overflow-y: auto;
  position: relative;
  height: calc(100% - 40px);
}

#editor-container {
  width: 1000px;
  margin: 30px auto 10px auto;
  background-color: #fff;
  padding: 20px 50px 50px 50px;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 10px rgb(0 0 0 / 12%);
  min-height: 300px;
}

#title-container {
  padding: 20px 0;
  border-bottom: 1px solid #e8e8e8;
}

#title-container input {
  font-size: 30px;
  border: 0;
  outline: none;
  width: 100%;
  line-height: 1;
}

#editor-text-area {
  min-height: 400px;
  margin-top: 20px;
}

.css-1fbmj9d {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  background-color: #ebebeb;
  height: 1px;
}

.css-1uz8l7v {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: #444444;
  line-height: 61px;
  font-size: 17px;
  font-weight: 500;
}

.w-e-toolbar {
  justify-content: center;
}

.css-mfq34p {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  padding-bottom: 11px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.css-1nj8b26 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: #444444;
  margin-right: 3px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  line-height: 38px;
  font-size: 15px;
  font-weight: 400;
  width: 110px;
  padding-left: 10px;
}

.css-1pysja1 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.css-1kx6s1g {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* width: 150px; */
  /* height: 100px; */
  /* border-radius: 4px;
  border: 1px dashed;
  border-color: #8590a6; */
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.css-uip80e {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: #999999;
  margin-top: 8px;
  font-size: 13px;
  line-height: 20px;
}
</style>
