<template>
  <div class="totalBg">
    <div class="container">
      <h4 class="card-title">{{ moduleData.forummoduleTitle }} 发表主题</h4>
      <!-- <el-input v-model="addTitle" placeholder="标题"></el-input> -->
      <!-- <div class="moduelTag" ref="moduleTag">
        <span>标签：</span><span :class="i.isTrue ? 'active tag' : 'tag'" style="margin-right: 5px" @click="clickTag(i)"
          v-for="i in tagList" :key="i.F_Id">{{ i.Tag_name }}</span>
      </div> -->
    </div>
    <Wangeditor class="editorContent" ref="richText"></Wangeditor>
    <div style="width: 100%; height: 50px">
      <el-button style="float: right; margin-right: 300px" @click="addForum" type="primary">发表主题</el-button>
    </div>
  </div>
</template>
<script>
import {
  GetForumModuleDetail,
  GetForumPostsList,
  GetForumModuleTagList,
  AddForumPost,
} from "@/assets/API/forum.js";
import Wangeditor from "@/components/wang/wangeditorLikeQQ";

export default {
  name: "AddForum",
  props: {
    data: {},
  },
  components: { Wangeditor },
  data() {
    return {
      dataForm: {
        id: "",
        postTitle: '',
        postContent: '',
        postText: '',
        postBiboid: '',
        postBibotitle: '',
        postSmboid: '',
        postSmboidtitle: '',
        postAdmin: '',
        postAdminid: '',
        postCreatetime: '',
        postGoodcount: undefined,
        postWatch: undefined,
        postDiscribe: '',
        postCommentcount: undefined,
        fEnabledmark: 0,
      },
      moduleData: {},
      tagList: [],
      // addTitle: "",
    };
  },
  created() {
    let moduleId = this.$route.query.data;
    GetForumModuleDetail(moduleId).then((res) => {
      this.moduleData = { ...res.data };
    });
    // let queryTag = {
    //   Tag_moduleId: moduleId,
    // };
    // GetForumModuleTagList(queryTag).then((res) => {
    //   for (var i of res.data.list) {
    //     i.isTrue = false;
    //   }
    //   this.tagList = [...res.data.list];
    // });
  },
  computed: {},
  watch: {},
  mounted() { },

  methods: {
    clickTag(i) {
      i.isTrue = !i.isTrue;
    },
    addForum() {

      this.dataForm.postTitle = this.$refs.richText.getTitleContain();
      this.dataForm.postBiboid = this.moduleData.biboId;
      this.dataForm.postBibotitle = this.moduleData.biboTitle;
      this.dataForm.postSmboidtitle = this.moduleData.forummoduleTitle;
      this.dataForm.postSmboid = this.moduleData.fId;
      this.dataForm.postAdmin = this.$store.state.user.userInfo.username;
      this.dataForm.postAdminid = this.$store.state.user.userInfo.id;
      // this.dataForm.postPosterurl = this.$refs.richText.imageUrl;
      this.dataForm.postDiscribe = this.$refs.richText.textarea;
      this.dataForm.postCreatetime = new Date().getTime();
      this.dataForm.postContent = this.$refs.richText.getEditorText();
      this.dataForm.postText = this.$refs.richText.getEditorText2();
      this.dataForm.postWatch = 0;
      // 内容检验
      if (!this.dataForm.postTitle || !this.dataForm.postContent) {
        this.$message.error("请填写标题和内容");
        return;
      }
      AddForumPost(this.dataForm).then((res) => {
        this.$message("发表成功！");
        this.$router.go(-1);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.totalBg {
  background: rgba(240, 245, 255, 0.95);
}

.container {
  width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 60px;
}

.card-title {
  margin: 20px 0;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.2;
  color: inherit;
  font-size: 20px;
}

.moduelTag {
  margin: 20px 0 !important;
  font-size: 12px;
  color: #868e96 !important;

  .active {
    font-weight: 800;
  }

  .tag {
    color: #18191a;
    margin-left: 10px;
  }
}

.editorContent {}
</style>
